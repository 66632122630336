import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { Box, Typography } from "@mui/material";
import { Control, FormState, UseFormGetValues, UseFormReset, UseFormSetValue } from "react-hook-form";
import { Organization, OrganizationLicense, OrganizationSubmission, RootState } from "../../../store";
import LicenseGrid from "./LicenseGrid";
import { GridData } from "./types";
import {  useSelector } from "react-redux";

export type OrganizationLicenseContainerProps = {
  organization: Partial<Organization> & { id: Organization['id'] }
  usedStarterLicenses: number;
  usedIndividualLicenses: number;
  usedGrowthLicenses: number;
  usedPremierLicenses: number;
  setUsedStarter: (used: number) => void;
  setUsedIndividual: (used: number) => void;
  setUsedGrowth: (used: number) => void;
  setUsedPremier: (used: number) => void;
  handleOpenLicenseModal: () => void
  openDeletionModal: boolean
  openChangesModal: boolean
  control: Control<GridData>
  getValues: UseFormGetValues<GridData>
  formState: FormState<GridData>
  setValue: UseFormSetValue<GridData>
  reset: UseFormReset<GridData>
  submitTable: () => Promise<void>
  rows: GridData["data"]
  setRows: (value: GridData["data"]) => void
  setCachedRows: (value: GridData["data"]) => void
  discardChanges: () => void
  handleCreateRow : (licenseData: OrganizationLicense) => Promise<void>
  canceledDeletions: () => void
  confirmedDeletions: () => void
  createdRows: GridData["data"] | null
  updatedRows: UpdateOrganizationLicense | null
  deletedRows: GridData["data"] | null
  canceledChanges: () => void
  confirmedChanges: (
    createdRows: GridData["data"] | null, 
    updatedRows: UpdateOrganizationLicense | null,
    deletedRows: GridData["data"] | null,
    rows: GridData["data"]
  ) => void
  org: OrganizationSubmission
  resendInvite: (license: OrganizationLicense) => void
};

export type UpdateOrganizationLicense =  {
  [id: number]: Partial<OrganizationLicense>
} | null;

const OrganizationLicenseContainer = ({ 
  organization, usedIndividualLicenses, usedStarterLicenses, setUsedIndividual, setUsedStarter,
  handleOpenLicenseModal, control, getValues, formState, setValue, reset, submitTable,
  rows, setRows, discardChanges, handleCreateRow, openChangesModal, openDeletionModal,
  canceledDeletions, confirmedDeletions, createdRows, updatedRows, deletedRows, canceledChanges,
  confirmedChanges, setUsedGrowth, setUsedPremier, usedGrowthLicenses, usedPremierLicenses, setCachedRows, org,
  resendInvite
}: OrganizationLicenseContainerProps) => {

  const user = useSelector((root: RootState) => root.auth.user);

  return <Box>
    <LicenseGrid
      control={control}
      getValues={getValues}
      formState={formState}
      setValue={setValue}
      organization={organization}
      handleSubmit={submitTable}
      reset={reset}
      rows={rows}
      setRows={setRows}
      discardChanges={discardChanges}
      usedIndividualLicenses={usedIndividualLicenses}
      usedStarterLicenses={usedStarterLicenses}
      usedGrowthLicenses={usedGrowthLicenses}
      usedPremierLicenses={usedPremierLicenses}
      setUsedIndividual={setUsedIndividual}
      setUsedStarter={setUsedStarter}
      setUsedGrowth={setUsedGrowth}
      setUsedPremier={setUsedPremier}
      onCreateLicense={handleCreateRow}
      user={user}
      handleOpenLicenseModal={handleOpenLicenseModal}
      setCachedRows={setCachedRows}
      org={org}
      resendInvite={resendInvite}
    />
    <CabModal title="Confirm deletions" open={openDeletionModal} actionButtons={
      <>
        <CabButton type="button" onClick={canceledDeletions} buttonType="tertiary">Cancel</CabButton>
        <CabButton type="button" onClick={confirmedDeletions}>Confirm</CabButton>
      </>
    }>
      <Typography sx={{ marginBottom: 2 }}>
        You're about to delete ({Object.values(deletedRows || {}).length}) licenses. This will remove the associated
        users from your organization and disable their ability to collaborate with others in your organization.
        If you wish to keep this user in your organization but remove their paid license, 
        you can downgrade them to basic.
      </Typography>
    </CabModal>
    <CabModal title="Confirm License Changes" open={openChangesModal} actionButtons={
      <>
        <CabButton type="button" onClick={canceledChanges} buttonType="tertiary">Cancel</CabButton>
        <CabButton
          type="button"
          onClick={() => confirmedChanges(createdRows, updatedRows, deletedRows, rows)}
        >
          Confirm
        </CabButton>
      </>
    }>
      <Typography sx={{ marginBottom: 2 }}>You've made the following changes to your license assignments.</Typography>
      <Typography>Created ({Object.values(createdRows || {}).length})</Typography>
      <Typography>Updated ({Object.values(updatedRows || {}).length})</Typography>
      <Typography>Deleted ({Object.values(deletedRows || {}).length})</Typography>
    </CabModal>
  </Box>;
};

export default OrganizationLicenseContainer;