import React, {ReactElement, useCallback, useEffect, useState } from 'react';
import classes from './Organization.module.css';
import {  
  AccountOwner, FetchReturn, Organization as OrgType,
  OrganizationLicense, OrganizationSubmission, SubscriptionDetails, ThunkDispatchType, User, actions 
} from '../../../store';
import FormControl from '@mui/material/FormControl';
import Dropzone from "react-dropzone";
import { Box, styled, Typography, Grid } from '@mui/material';
import { CabButton, CabTextInput } from '@CabComponents';
import OrganizationLicenseContainer, { 
  UpdateOrganizationLicense 
} from '../../../components/Settings/OrganizationLicense';
import colors from '../../../colors';
import { BILLING_INTERVAL, TIER } from '../../../constants';
import { SubSummary } from '../Subscription/SubSummary';
import { GridData } from '../../../components/Settings/OrganizationLicense/types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import UpgradeOverlay from '../../../components/Common/UpgradeOverlay/UpgradeOverlay';

const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  width: '100%',
  height: '100%',
  marginTop: 8,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: '4px',
}));

const TitleText = styled("div", {label: "TitleText"})({
  padding: "12px",
  fontSize: "14px",
  fontWeight: "bold",
});

interface Props {
  org: OrganizationSubmission;
  updateOrganization: (org: OrganizationSubmission) => Promise<FetchReturn<OrgType> | undefined>;
  processOrganizationLogo: (org: OrganizationSubmission) => Promise<FetchReturn<{data: string}>>;
  accountOwner: AccountOwner | undefined;
  user: User | undefined | null;
  subscriptionDetails: SubscriptionDetails | null;
  confirmButtonDisabled: boolean;
  dialogError: string;
  redirectDialogOpen: boolean;
  clearPaymentDialogError: () =>  void;
  resendInvite: (license: OrganizationLicense) => void;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
}

export const Organization = ({
  org, updateOrganization, processOrganizationLogo, user, accountOwner, subscriptionDetails,
  onChangeSubscription, confirmButtonDisabled, dialogError, redirectDialogOpen,
  clearPaymentDialogError, resendInvite
}: Props): ReactElement => {
  const [formChange, setFormChange] = useState(false);
  const [localOrg, setLocalOrg] = useState<OrganizationSubmission>({
    id: org.id,
    name: org.name,
    logo_file: org.logo_file,
    logo: org.logo,
    remove_background: false,
    resample_type: "nearest",
  });
  const [file, setFile] = useState<File | null>(null);
  const [usedStarterLicenses, setUsedStarterLicenses] = useState(0);
  const [usedIndividualLicenses, setUsedIndividualLicenses] = useState(0);
  const [usedGrowthLicenses, setUsedGrowthLicenses] = useState(0);
  const [usedPremierLicenses, setUsedPremierLicenses] = useState(0);
  const [quantityDialogOpen, setQuantityDialogOpen] = useState(false);
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const [openChangesModal, setOpenChangesModal] = useState(false);
  
  const {
    control, getValues, formState, setValue, handleSubmit, trigger, reset
  } = useForm<GridData>({ defaultValues: { data: org.licenses || {} }, reValidateMode: "onBlur" });

  const [createdRows, setCreatedRows] = useState<GridData["data"] | null>(null);
  const [updatedRows, setUpdatedRows] = useState<UpdateOrganizationLicense>(null);
  const [deletedRows, setDeletedRows] = useState<GridData["data"] | null>(null);
  const [rows, setRows] = useState<GridData['data']>(getValues("data"));
  const [cachedRows, setCachedRows] = useState<GridData['data']>(getValues("data"));

  useEffect(() => {
    setLocalOrg({
      id: org.id,
      name: org.name,
      logo_file: org.logo_file,
      logo: org.logo,
      remove_background: false,
      resample_type: "nearest",
    });
  }, [org]);

  const handleSetUsedStarter = (used: number) => {
    setUsedStarterLicenses(used);
  };

  const handleSetUsedIndividual = (used: number) => {
    setUsedIndividualLicenses(used);
  };

  const handleSetUsedGrowth = (used: number) => {
    setUsedGrowthLicenses(used);
  };

  const handleSetUsedPremier = (used: number) => {
    setUsedPremierLicenses(used);
  };

  const handleUpdateOrganization = async (orgUpdate: OrganizationSubmission) => {
    const res = await updateOrganization(orgUpdate);
    if (res && res.status === 200) {
      setLocalOrg({...localOrg, ...res.data});
    }
  };

  const handleOrgMetaSubmit = (e: React.FormEvent) => {
    setFormChange(false);
    e.preventDefault();
    const submitOrg = {...localOrg};
    if (file) {
      submitOrg["logo_file"] = file;
    }
    handleUpdateOrganization(submitOrg);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFormChange(false);
    e.preventDefault();
    setLocalOrg({...org, remove_background: false, resample_type: "nearest"});
  };


  const dispatch = useDispatch<ThunkDispatchType>();

  const modalPopUpLogic = useCallback((
    passedUpdatedRows: UpdateOrganizationLicense,
    passedCreatedRows: GridData["data"] | null,
    passedDeletedRows: GridData["data"] | null,
    curOpenChangesModal: boolean
  ) => {
    if (Object.values(passedDeletedRows || {}).length > 0 && !curOpenChangesModal) {
      setOpenDeletionModal(true);
    } else if (Object.values(passedCreatedRows || {}).length > 0 || Object.values(passedUpdatedRows || {}).length > 0) {
      setOpenChangesModal(true);
    }
  }, []);

  useEffect(() => {
    setRows(org.licenses || {});
    reset({ data: org.licenses || {} });
  }, [org.licenses, reset]);

  const submitTableSuccess = useCallback(async (data: GridData, openModal: boolean) => {
    const tempCreatedRows: GridData["data"] = {};
    const tempUpdatedRows: { [id: number]: Partial<OrganizationLicense> } = {};
    const tempDeletedRows: GridData["data"] = {};

    if (org.licenses) {
      Object.entries(data["data"]).forEach(([id, licenseData]) => {
        if (licenseData.id < 0) {
          tempCreatedRows[licenseData.id] = licenseData;
        } else if (licenseData.id === 0) {
          tempDeletedRows[Number(id)] = { ...licenseData, id: Number(id) };
        }
      });
    }
    if (formState.dirtyFields.data !== undefined) {
      Object.entries(formState.dirtyFields.data).forEach(
        ([key, value]) => {
          if (tempCreatedRows[Number(key)] === undefined && tempDeletedRows[Number(key)] === undefined) {
            tempUpdatedRows[Number(key)] = data["data"][Number(key)];
          }
        });
    }

    setCreatedRows(tempCreatedRows);
    setUpdatedRows(tempUpdatedRows);
    setDeletedRows(tempDeletedRows);
    if (openModal) {
      modalPopUpLogic(tempUpdatedRows, tempCreatedRows, tempDeletedRows, openChangesModal);
    }
  }, [formState.dirtyFields.data, modalPopUpLogic, openChangesModal, org.licenses]);

  const submitTable = useCallback(async () => {
    const valid = await trigger();
    if (valid) {
      handleSubmit((data) => submitTableSuccess(data, true))();
    }
  }, [handleSubmit, submitTableSuccess, trigger]);
  
  const handleOpenLicenseModal = useCallback(async () => {
    setQuantityDialogOpen(true);
    const valid = await trigger();
    if (valid) {
      handleSubmit((data) => submitTableSuccess(data, false))();
    }
  }, [handleSubmit, submitTableSuccess, trigger]);

  const onAddAdditionalLicensesCancel = () => setRows(cachedRows);

  const confirmedChanges = useCallback(async (
    newCreatedRows: GridData["data"] | null,
    newUpdatedRows: UpdateOrganizationLicense | null,
    newDeletedRows: GridData["data"] | null,
    currentRows: GridData["data"]
  ) => {

    const createdLicenses = (newCreatedRows && Object.keys(newCreatedRows).length > 0) ?
      (await dispatch(actions.organization.createOrganizationLicenses(newCreatedRows)))
      : null;
    const updatedLicenses = (newUpdatedRows && Object.keys(newUpdatedRows).length > 0) ?
      (await dispatch(actions.organization.bulkUpdateOrganizationLicenses(newUpdatedRows)))
      : null;
    const deletedLicenses = (newDeletedRows && Object.keys(newDeletedRows).length > 0) ?
      (await dispatch(actions.organization.deleteOrganizationLicenses(Object.keys(newDeletedRows).map(Number))))
      : null;
    let newRows = { ...currentRows };
    if (createdLicenses && createdLicenses.status === 201) {
      newRows = { ...createdLicenses.data.created, ...newRows };
    }

    if (updatedLicenses && updatedLicenses.status === 200) {
      newRows = { ...newRows, ...updatedLicenses.data.updated };
    }

    if (deletedLicenses && deletedLicenses.status === 200) {
      deletedLicenses.data.deleted.forEach(id => {
        if (newRows[id]) delete newRows[id];
      });
    }
  
    const cleanCreated = { ...newRows };
    Object.values(newRows).forEach((newRowEntry) => {
      if (newRowEntry.id < 0) {
        delete cleanCreated[newRowEntry.id];
      }
    });
    setRows(cleanCreated);
    reset({ data: cleanCreated });
    setCreatedRows(null);
    setUpdatedRows(null);
    setDeletedRows(null);
    setOpenChangesModal(false);
  }, [dispatch, reset]);

  const canceledChanges = () => {
    setCreatedRows(null);
    setUpdatedRows(null);
    setDeletedRows(null);
    setOpenChangesModal(false);
  };

  const handleSetRows = (value: GridData["data"]) => {
    setRows(value);
  };

  const discardChanges = useCallback(() => {
    reset({ data: org.licenses || {} });
    if (org.licenses) {
      setRows(org.licenses);
    }
  }, [org.licenses, reset]);

  const canceledDeletions = () => {
    const dataRows = {...rows};

    Object.values(deletedRows || {}).forEach((datum) => {
      dataRows[datum.id] = datum;
    });
    reset({ data: dataRows });
    Object.values(updatedRows || {}).forEach((row) => {
      const key = `data.${row.id}.email`;
      setValue(key, row.email, { shouldDirty: true });
    });
    setRows(dataRows);
    setDeletedRows(null);
    setOpenDeletionModal(false);
  };

  const confirmedDeletions = () => {
    setOpenDeletionModal(false);
    setOpenChangesModal(true);
  };

  const handleCreateRow = async (licenseData: OrganizationLicense) => {
    const tempCreatedRows: GridData["data"] = {};

    if (org.licenses) {
      if (licenseData.id < 0) {
        tempCreatedRows[licenseData.id] = licenseData;
      }
    }
    setValue("data", { ...getValues("data"), ...tempCreatedRows }, { shouldDirty: false });
    setCreatedRows(tempCreatedRows);
    setOpenChangesModal(true);
  };

  const onLicenseChangeSuccess = useCallback(() => {
    confirmedChanges(createdRows, updatedRows, deletedRows, rows);
  }, [confirmedChanges, createdRows, deletedRows, rows, updatedRows]);

  const logoEditable = (
    user?.features?.WHITELABEL_LOGO  && 
    user?.permissions.CONTENT_EDITOR
  );

  return (
    <Grid container spacing={2} className={classes.organization}>
      <Grid item xs={12} md={user?.permissions.ORGANIZATION_LICENSE_TABLE ? 7 : 12} 
        lg={user?.permissions.ORGANIZATION_LICENSE_TABLE ? 6 : 12}>
        <Box>
          <Typography variant="h1" sx={{ marginTop: 2 }}>Organization Details</Typography>
          <StyledBox>
            <FormControl fullWidth={true}>
              <form onSubmit={handleOrgMetaSubmit} onChange={() => setFormChange(true)}>
                <div className={classes.borderBottom}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TitleText>Organization Name</TitleText>
                      { user?.permissions.CONTENT_EDITOR ?
                        <div className={classes.paddingWrapperX}>
                          <CabTextInput
                            placeholder='Organization Name'
                            type={'text'}
                            value={localOrg.name}
                            onChange={(e) => setLocalOrg({...localOrg, name: e.target.value})}
                            required
                            fullWidth
                            sx={{ marginTop: "4px", marginBottom: "8px" }}
                          />
                        </div>
                        :
                        <Typography marginBottom={1} marginLeft={1.5}>{localOrg.name}</Typography>
                      }
                    
                    </Grid>
                  </Grid>
                </div>
              
                <div>
                  <TitleText>
                    { user?.permissions.CONTENT_EDITOR && user?.features.WHITELABEL_LOGO ?
                      "Upload Organization Logo"
                      :
                      "Organization Logo"
                    }
                  </TitleText>
                  <div className={classes.paddingWrapperX}>
                    <OrganizationImage
                      localOrg={localOrg}
                      setLocalOrg={(newLocalOrg) => {
                        setLocalOrg(newLocalOrg);
                        setFormChange(true);
                      }}
                      file={file}
                      setFile={setFile}
                      processOrganizationLogo={processOrganizationLogo}
                      isContentEditor={!!logoEditable}
                      locked={!logoEditable}
                    />
                    {
                      !user?.features?.WHITELABEL_LOGO && (
                        <UpgradeOverlay
                          show={!logoEditable}
                          overlaySx={{
                            top: 100, height: '260px', position: 'absolute', width: '100%', 
                            backgroundColor: 'rgba(0, 0, 0, .8)', left: 0, justifyContent: 'center', display: 'flex',
                          }}
                          message={'Upgrade your subscription plan to add your logo to booking pages'} />
                      )
                    }
                  </div>
                </div>
              
                <Grid container>
                  <Grid item xs={12} className={classes.buttonWrapper}>
                    {user?.permissions.CONTENT_EDITOR ? (
                      formChange && (
                        <>
                          <CabButton
                            buttonType='tertiary' 
                            color="primary"
                            onClick={handleCancel}
                            sx={{marginBottom: 2}}
                          >
                            Cancel
                          </CabButton>
                          <CabButton
                            type='submit'
                            sx={{marginBottom: 2}}
                          >
                            Save Organization
                          </CabButton>
                        </>
                      )
                    ) : ( 
                      accountOwner
                        ? <Box width={'100%'} textAlign='center' marginBottom={1} marginTop={1}>
                          {`To change organization information, contact your account administrator
                          (${accountOwner.first_name} ${accountOwner.last_name}, ${accountOwner.email})`}
                        </Box>
                        : <></>
                    )}
                  
                  </Grid>
                </Grid>
              </form>
            
            </FormControl>
          </StyledBox>
        </Box>
      </Grid>
      {user?.permissions.ORGANIZATION_LICENSE_TABLE &&
      <Grid item xs={12} md={5} lg={6}>
        <Box height='calc(100% - 55px)'>
          <Typography variant="h1" sx={{ marginTop: 2, marginBottom: '8px' }}>Licenses</Typography>
          <SubSummary
            isOnContract={!!org.is_on_contract}
            subscriptionDetails={subscriptionDetails}
            confirmButtonDisabled={confirmButtonDisabled}
            dialogError={dialogError}
            onChangeSubscription={onChangeSubscription}
            fullHeight
            clearPaymentDialogError={clearPaymentDialogError}
            quantityDialogOpen={quantityDialogOpen}
            handleQuantityDialogOpen={(value) => setQuantityDialogOpen(value)}
            isStripeManager={!!user?.permissions.STRIPE_SUBSCRIPTION_MGMT}
            onAddAdditionalLicensesCancel={onAddAdditionalLicensesCancel }
            onLicenseChangeSuccess={onLicenseChangeSuccess}
          />
        </Box>
      </Grid>
      }
      {user?.permissions.ORGANIZATION_LICENSE_TABLE &&
        <Grid item xs={12} md={12}>
          <Typography variant="h1" sx={{ marginBottom: 1 }}>Users</Typography>
          <OrganizationLicenseContainer 
            organization={org}
            usedStarterLicenses={usedStarterLicenses}
            usedIndividualLicenses={usedIndividualLicenses}
            usedGrowthLicenses={usedGrowthLicenses}
            usedPremierLicenses={usedPremierLicenses}
            setUsedIndividual={handleSetUsedIndividual}
            setUsedStarter={handleSetUsedStarter}
            setUsedGrowth={handleSetUsedGrowth}
            setUsedPremier={handleSetUsedPremier}
            handleOpenLicenseModal={handleOpenLicenseModal}
            createdRows={createdRows}
            updatedRows={updatedRows}
            deletedRows={deletedRows}
            canceledChanges={canceledChanges}
            canceledDeletions={canceledDeletions}
            confirmedChanges={confirmedChanges}
            confirmedDeletions={confirmedDeletions}
            setRows={handleSetRows}
            control={control}
            setValue={setValue}
            getValues={getValues}
            handleCreateRow={handleCreateRow}
            formState={formState}
            reset={reset}
            submitTable={submitTable}
            openDeletionModal={openDeletionModal}
            openChangesModal={openChangesModal}
            rows={rows || {}}
            discardChanges={discardChanges}
            setCachedRows={(value) => setCachedRows(value)}
            org={org}
            resendInvite={resendInvite}
          />
        </Grid>
      }
    </Grid>
  );
};

interface OrganizationImageProps {
  localOrg: OrganizationSubmission
  setLocalOrg: React.Dispatch<React.SetStateAction<OrganizationSubmission>>
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  processOrganizationLogo: (org: OrganizationSubmission) => Promise<FetchReturn<{data: string}>>
  isContentEditor: boolean
  locked: boolean
}



const OrganizationImage = (
  {localOrg, setLocalOrg, file, setFile, processOrganizationLogo, isContentEditor, locked}: OrganizationImageProps
): ReactElement => {

  const [errorMessage, setErrorMessage] = useState<string|null>(null);

  async function handleLogoImageDrop(submitOrg: OrganizationSubmission, logoFile: File | null) {
    setFile(logoFile);
    const res = await processOrganizationLogo({...submitOrg, logo_file: logoFile});
    if (logoFile && res.status === 200) {
      setLocalOrg({...submitOrg, logo:`data:${logoFile.type};base64,${res.data["data"]}`});
    }
    
  }

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles[0].size > 2500000) {
      setErrorMessage("Max file size is 2.5Mb");
    } else if (!["image/png", "image/jpeg"].includes(acceptedFiles[0].type)) {
      setErrorMessage("Supported file types include png & jpeg");
    } else {
      setErrorMessage(null);
      handleLogoImageDrop(localOrg, acceptedFiles[0]);
    }
  };

  // NOTE: 174 to 187 were commented out because we've temporaily removed background removal until we can improve the
  // algorithm for complex logo colors.

  // const handleRemoveBackground = () => {
  //   const newOrg = {...localOrg, remove_background: !localOrg.remove_background};
  //   setLocalOrg(newOrg);
  //   if (file){
  //     handleLogoImageDrop(newOrg, file);
  //   }
  // };

  //let showRemoveBackground = false;

  // if (typeof localOrg.logo === "string" && !localOrg.logo.includes(";base64,")){
  //   showRemoveBackground = true;
  // }

  return (<div className={classes.imageCard}>
    <Box marginTop={1}>
      {isContentEditor ?
        <>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <Box display='flex'  {...getRootProps()}>
                <Box marginRight={2}>
                  {localOrg.logo ?
                    <Box component='img' src={localOrg.logo || ""} alt={`${localOrg.name}-logo`} 
                      maxWidth={200} height='auto' border={`1px solid ${colors.black200}`} padding={1} 
                      borderRadius={'6px'} sx={{backgroundSize: '20px 20px', backgroundColor: colors.white900}}/>
                    :
                    <div className={classes.emptyimage} />
                  }
                </Box>
                <div className={classes.innerDragAndDropContainer}>
                  <div className={classes.containerDrop}>
                    <input {...getInputProps()} />
                    <Box sx={{cursor: 'pointer'}}>
                      <Typography variant="body1" className={classes.title}>
                        Drag & drop file or <span className={classes.falseLink}>click</span>
                      </Typography>
                      <Typography variant="body2" lineHeight={'30px'} color={colors.black700}>
                        JPG, GIF, PNG. Max 2.5Mb 
                      </Typography>
                    </Box>
                    {errorMessage &&
                      <span className={classes.errorColor}>Error: {errorMessage}</span>
                    }

                  </div>
                </div>
              </Box>
            )}
          </Dropzone>
        </>
        :
        <Box marginRight={2} minHeight={locked ? '200px' : 'unset'}>
          {localOrg.logo ?
            <Box component='img' src={localOrg.logo || ""} alt={`${localOrg.name}-logo`} maxWidth={200} height='auto'
              border={`1px solid ${colors.black200}`} padding={1} borderRadius={'6px'} 
              sx={{backgroundSize: '20px 20px', backgroundColor: colors.white900}}/>
            :
            <div className={classes.emptyimage} />
          }
        </Box>
      }
    </Box>
  </div>);
};

export default Organization;
